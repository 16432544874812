import { Box, Flex } from "@chakra-ui/layout";
import { SlideFade } from "@chakra-ui/transition";
import React from "react";

type LayoutProps = {
  children: React.ReactNodeArray | React.ReactChild;
};

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      as="header"
      w="full"
      py="4"
      position={"fixed"}
      zIndex={"1"}
      px={["30px","30px","100px","100px"]}
      style={{ boxShadow: "4px 4px 8px rgb(189 182 182 / 20%)" }}
      backgroundColor={"white"}
      boxShadow="sm"
      borderBottomWidth="1px"
      borderBottomColor="brand.Gray7"
      flexDir="row"
      justify="space-between"
     
    >
      {children}
    </Flex>
  );
};

const Content = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      w="full"
      backgroundColor={"brand.white"}
      flex="2"
      zIndex={"0"}
      id="contentDiv"
      overflow={"hidden"}
    >
      <SlideFade
        in={true}
        offsetY="20px"
        style={{ width: "100%", height: "100%" }}
      >
        {children}
      </SlideFade>
    </Box>
  );
};

const Footer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      as="footer"
      w="full"
      // p="4"
      zIndex={"1"}
      id="flowFooter"
      // style={{ boxShadow:"10px -8px 10px gray"}}
      style={{ boxShadow: "4px -4px 8px rgb(189 182 182 / 20%)" }}
      borderTopWidth="1px"
      borderTopColor="brand.Gray7"
    
    >
      {children}
    </Box>
  );
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <Flex   flexDir="column" w="full" h="full">
      {children}
    </Flex>
  );
};

Layout.Header = Header;
Layout.Content = Content;
Layout.Footer = Footer;

export default Layout;
