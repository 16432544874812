import { Divider, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import s1 from "../Assets/productImage/1.jpg";
import s2 from "../Assets/productImage/2.jpg";
import s3 from "../Assets/productImage/3.jpg";
import s4 from "../Assets/productImage/4.jpg";
import s5 from "../Assets/productImage/5.jpg";
import s6 from "../Assets/productImage/6.jpg";
import s8 from "../Assets/productImage/8.jpg";
import s9 from "../Assets/productImage/9.jpg";
import s10 from "../Assets/productImage/10.jpg";
import s12 from "../Assets/productImage/12.jpg";
import s14 from "../Assets/productImage/14.jpg";
import s15 from "../Assets/productImage/15.jpg";
import s17 from "../Assets/productImage/17.jpg";
import s18 from "../Assets/productImage/18.jpg";
import s19 from "../Assets/productImage/19.jpg";
import s20 from "../Assets/productImage/20.jpg";
import s21 from "../Assets/productImage/21.jpg";
import s22 from "../Assets/productImage/22.jpg";
import s23 from "../Assets/productImage/23.jpeg";
import s24 from "../Assets/productImage/24.jpeg";
import s25 from "../Assets/productImage/25.jpeg";

import { motion } from "framer-motion";
let data = [
  {
    id: 1,
    pic: s1,
    name: "Accufitt CO-Q™  (Tablets)",
    details: [
      "Coenzyme Q10, With EPA, DHA, L-Arginine, Zinc Sulphate & L-Glutathione Tablets",
    ],
  },
  {
    id: 2,
    pic: s2,
    name: "Accufitt-LC™  (Tablets)",
    details: [
      "In Muscle Cramps & Chronic Fatigue",
      "L-Carnitine L-Tartrate 500mg. + Methycobalamin 750mcg. + Vitamin E 100mg. + L-Arginine 100mg.",
    ],
  },
  {
    id: 3,
    pic: s3,
    name: "Oxilyk™  (Capsules)",
    details: [
      "Rejuvenate Health",
      "Lycopene (10%) 7500mcg. + Vitamin A (Acetate) 2500IU + Vitamin E (Acetate) 50IU + Vitamin C (Ascorbic Acid) 50mg. + Vitamin B1 5mg. + Vitamin B2 3mg. + Vitamin B6 1.5mg. + Vitamin B12 5mcg. + Lutein 5000mcg. +  Copper Sulphate 1 mg. + Manganese Sulphate 1mg. + Potassium Sulphate 1 mg + Folic Acid 1 mg.",
    ],
  },
  {
    id: 11,
    pic: s4,
    name: "Kurejoints™  (Sachet)",
    details: [
      "For Management of Osteoarthritis",
      "Glucosamine Hydrochloride 1500mg. + Bromelain 600mg. + Methyl Sulphonyl Methane 500mg. + Boswellia serrata Extract 500mg. + Quercetin 500mg. + Chondroitin Suplate 400mg. +Pine Bark Extract 150mg. + Collagen type II unden 40mg.",
    ],
  },
  {
    id: 22,
    pic: s5,
    name: "Accumune™  (Syrup)",
    details: ["Methyl cobalamin, Multivitamin & Multimineral Syrup"],
  },
  {
    id: 33,
    pic: s6,
    name: "Accumune™ (Capsules)",
    details: [
      "Green Tea Extract, Ginseng, Ginkgo Biloba, Lycopene Garlic L-Carnitine-Tartrate with Vitamins & Minerals",
    ],
  },
  
  {
    id: 55,
    pic: s8,
    name: "Accubid-500 (Tablets)",
    details: [
      "Cefuroxime is used to treat a wide variety of bacterial infections.",
    ],
  },
  {
    id: 66,
    pic: s9,
    name: "D3 ERGO",
    details: [
      "It strengthens bones and teeth.",
      "Vitamin D is a fat-soluble vitamin that helps the body fight against infections and supports a healthy immune system.",
      "Cholecalciferol (Vitamin D3)",
    ],
  },
  {
    id: 66,
    pic: s10,
    name: "BrutzoxⓇ (Tablets)",
    details: ["Bromelain+Trypsin+Rutoside is used for pain relief."],
  },
 
  {
    id: 88,
    pic: s12,
    name: "Calove-K2 (Tablets)",
    details: [
      "It addresses a variety of illnesses caused by low calcium levels in the body. ",
      "Calcium deficiency, osteoporosis.",
    ],
  },
  
  {
    id: 82,
    pic: s14,
    name: "",
    details: [""],
  },
  {
    id: 83,
    pic: s15,
    name: "",
    details: [""],
  },

  {
    id: 87,
    pic: s17,
    name: "",
    details: [""],
  },
  {
    id: 89,
    pic: s18,
    name: "",
    details: [""],
  },
  {
    id: 90,
    pic: s19,
    name: "",
    details: [""],
  },
  {
    id: 91,
    pic: s20,
    name: "",
    details: [""],
  },
  {
    id: 92,
    pic: s21,
    name: "",
    details: [""],
  },
  {
    id: 93,
    pic: s22,
    name: "",
    details: [""],
  },
  {
    id: 94,
    pic: s23,
    name: "",
    details: [""],
  },
  {
    id: 95,
    pic: s24,
    name: "",
    details: [""],
  },
  {
    id: 96,
    pic: s25,
    name: "",
    details: [""],
  },
];

const GalleryPage = () => {
  return (
    <section id="product">
      <Flex
           bg="#edf3f8"
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width="full"
        p="10"
        
      >
        <Text fontSize={"4xl"}>Our Products</Text>
        <Divider mt="4" width={"100px"} color={"black"} />
        <Grid
          mt="10"
          mx={["10", "10", "40", "40"]}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
          ]}
          gap={6}
        >
          {data.map((d) => {
            return (
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{
                  ease: "linear",
                  x: { duration: 0.8 },
                  opacity: { duration: 1 },
                }}
              >
                <GridItem key={d.id}>
                  <Flex
                    justifyContent="center" // Center content horizontally
                    alignItems="center" // Center content vertically
                    flexDir="column"
                    boxShadow="lg"
                    width={["", "", "md", "md"]}
                    height={["", "", "md", "md"]}
                    px="2"
                    py="4"
                    borderRadius="lg"
                    textAlign="center"
                  >
                    <Image src={d.pic} />
                  </Flex>
                </GridItem>
              </motion.div>
            );
          })}
        </Grid>
      </Flex>
    </section>
  );
};

export default GalleryPage;
