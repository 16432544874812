import { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";
import emailjs from 'emailjs-com';
interface FormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

function FormPage() {
  const toast = useToast();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    emailjs.init('k9szP691pKisIlxEN'); // Initialize EmailJS with your User ID
}, []);

const sendEmail = (e: React.FormEvent) => {
  e.preventDefault();
//@ts-ignore
  emailjs.sendForm('service_9i0nuej', 'template_3f05gut', formRef.current)
    .then(() => {
      toast({
        title: 'Successful',
        description: 'Message sent successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    })
    .catch((error) => {
      console.error(error);
      toast({
        title: 'Error',
        description: 'Failed to send message.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
};

  return (
    <section id="form">
      <Flex
        bg="#edf3f8"
        py="10"
        flexDir={[
          "column-reverse",
          "column-reverse",
          "row-reverse",
          "row-reverse",
        ]}
        _dark={{ bg: "#3e3e3e" }}
        style={{
          backgroundImage:
            "url(http://webdesign-finder.com/pharmaton-v2/wp-content/uploads/2022/04/map-texture.png)",
        }}
      >
        <Container
  w={["full", "full", "50%", "50%"]}
  color={"black"}
  pb={["1.5rem", "1.5rem", "12", "12"]}
  alignItems="center"
>
  <Text fontSize={"3xl"} fontWeight={"semibold"}>
    Contact Form
  </Text>
  
  <form ref={formRef} onSubmit={sendEmail}>
    <Grid mt="10" templateColumns="repeat(2, 1fr)" gap={6}>
      <GridItem>
        <Input
          name="from_name"
          placeholder="Name"
        
        />
      </GridItem>
      <GridItem>
        <Input
          name="user_email"
          placeholder="Email"
         
        />
      </GridItem>
      <GridItem>
        <Input
          name="user_contact"
          placeholder="Phone"
       
        />
      </GridItem>
 
    </Grid>
    <Textarea
      mt="6"
      name="message"
      placeholder="Message"
     
    ></Textarea>
    <Button
      mt="10"
      rounded={"full"}
      colorScheme="red"
      variant={"outline"}
      type="submit"
    >
      Submit
    </Button>
  </form>
</Container>
        <Container
          w={["full", "full", "50%", "50%"]}
          color={"black"}
          pb={["1.5rem", "1.5rem", "12", "12"]}
          
        pt={["1.5rem", "1.5rem", "12", "12"]}
        >
          <Flex my="8" color="teal" justifyContent={"flex-start"} alignItems={"center"}>
          <IoLocationOutline />
            <Text
              fontWeight={"semibold"}
              fontSize="smaller"
              mx="2"
              _dark={{ color: "white" }}
            >
              {" "}
              119 first floor Gupta complex inderlok New Delhi 110035
            </Text>
          </Flex>
          <Flex my="8" color="teal"  justifyContent={"flex-start"} alignItems={"center"}>
            <BsFillTelephoneOutboundFill />
            <Text
             mx="2"
              fontWeight={"semibold"}
              fontSize="smaller"
              _dark={{ color: "white" }}
            >
              {" "}
              +91 11 4476 5213, +91 99710 36912, +91 99106 63912
            </Text>
          </Flex>
          <Flex my="8" color="teal"   onClick={()=> window.open("mailto:Info@accuffylifesciences.com")} justifyContent={"flex-start"} alignItems={"center"}>
            <IoMailOutline />
            <Text
            mx="2"
              fontWeight={"semibold"}
              fontSize="smaller"
              _dark={{ color: "white" }}
            >
              {" "}
              Info@accuffylifesciences.com
            </Text>
          </Flex>
        </Container>
      </Flex>
    </section>
  );
}

export default FormPage;
