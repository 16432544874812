import { Button, Flex, Icon, IconButton, Image, Link, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import Layout from '../Utils/Layout';
import Logo from "../Assets/Image/lpogo.jpg";

import HeroPage from '../Utils/Heropage';
import Footer from '../Utils/Footer';

import FormPage from '../Utils/FormPage';

import { RxHamburgerMenu } from 'react-icons/rx';
import GalleryPage from '../Utils/GalleryPage';
import Midpage from '../Utils/Midpage';
import Features from '../Utils/Features';

const HomePage = () => {

 function scrollToSection(sectionId:string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling animation
        block: 'start',     // Scroll to the top of the section
      });
    }
  }
  return (
    <Layout>
     
      <Layout.Content>
      <Layout.Header>
        <Image h="50" src={Logo}></Image>
        <Flex display={["none","none","flex","flex"]} w={["","","sm","md"]} justifyContent={"space-between"} color="black" alignItems="center">
          <Link mx="2" onClick={()=>scrollToSection("home")}> <Text>Home</Text> </Link>
          <Link  onClick={()=>scrollToSection("about")} mx="2">
          <Text>About Us</Text>
          </Link>
          <Link onClick={()=>scrollToSection("product")} mx="2">
          <Text>Products</Text>
          </Link>
          <Link  onClick={()=>scrollToSection("form")} mx="2">
          <Text>Contact Us</Text>
          </Link>
          
       
        </Flex>
        <Flex  display={["flex","flex","none","none",]}>
        <Menu>
  <MenuButton
  colorScheme='blue'
    as={IconButton}
    aria-label='Options'
    icon={<RxHamburgerMenu color='blue'/>}
    border={"1px solid blue"}
    variant='ghost'
  />
  <MenuList><MenuItem>  <Link mx="2"  href="/#home" > <Text>Home</Text> </Link></MenuItem>

          <MenuItem><Link  href="/#about"  mx="2">
          <Text>About Us</Text>
          </Link></MenuItem>
          <MenuItem><Link  href="/#product"  mx="2">
          <Text>Products</Text>
          </Link></MenuItem>
         <MenuItem> <Link  href="/#form"  mx="2">
          <Text>Contact Us</Text>
          </Link></MenuItem>
  </MenuList>
</Menu>
        </Flex>
      </Layout.Header>
        <HeroPage />
        <Features/>
        <Midpage/>
        <GalleryPage />

       <FormPage/>
       <Layout.Footer>
        <Footer />
      </Layout.Footer>
      </Layout.Content>
     
    </Layout>
  )
}

export default HomePage