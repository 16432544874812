import { Box, Button, Center,  Text } from "@chakra-ui/react";
import slide1 from "../Assets/Image/Slide1.jpg"


export default function HeroPage(){
  function scrollToSection(sectionId:string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling animation
        block: 'start',     // Scroll to the top of the section
      });
    }
  }
  return(
    <section id="home">
    <Box height={"100vh"}  w="100%"   bgImage={"https://himeruswellness.com/img/bg-1.jpg" } bgRepeat={"no-repeat"} bgSize={"cover"}>
    <Box
            height={"100vh"}  w="100%" 
            
              // zIndex="overlay"
              opacity="1"
              position="absolute"
              top="0"
              left="0"
              right={"0"}
              bottom="0"
              alignItems={"center"}
              justifyContent="center"
              borderRadius="15px"
              bgGradient="linear-gradient(45deg, rgba(24, 87, 64, 0.8), rgba(29, 200, 205, 0.8))"
              // bgColor={"black"}
            >
            
            </Box>
      <Box
              zIndex="0"
              opacity="1"
              position="relative"
              top="0"
              left="0"
              h={["500px","500px","700px","700px"]}
              right={"0"}
              bottom="0"
              alignItems={"center"}
              justifyContent="center"
              borderRadius="15px"
              
            >
              <Center justifyContent={"center"} alignItems={"center"} w="full" flexDir={"column"} h="full">
              <Text fontSize={["3xl","3xl","5xl","5xl"]} color="Black"  px={["10px","10px","",""]} textAlign={"center"} fontWeight={"medium"}>Welcome to Accuffy Lifesciences</Text>
              <Text w={["full","full","container.md","container.md"]} my={"8"}  px={["10px","10px","",""]} textAlign={"center"} fontSize={["lg","lg","xl","xl",]} color="Black" fontWeight={"medium"}>Your health is our priority.</Text>
              <Button onClick={()=>scrollToSection("form")}  colorScheme='gray' px="8" rounded={"full"} variant='outline'>
            Get In Touch
  </Button>
              </Center>
            </Box>
            
    </Box>
    </section>
  )
  };