import React from "react";
import { chakra, Box, Flex, Stack,  Button } from "@chakra-ui/react";
import { Transition } from "react-transition-group";
import { motion } from "framer-motion";

export default function Midpage(){
  function scrollToSection(sectionId:string) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling animation
        block: 'start',     // Scroll to the top of the section
      });
    }
  }
  return (
    


         
      
    <Flex
    style={{backgroundSize:"cover", background: "linear-gradient(45deg, rgba(24, 87, 64, 0.8), rgba(29, 200, 205, 0.8))"}}
      _dark={{ bg: "#3e3e3e" }}
      p={50}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      
      <Box bg="transparent" _dark={{ bg: "transparent" }}>
        <Box
          maxW="7xl"
        
          mx="auto"
          py={{ base: 12, lg: 16 }}
          px={{ base: 4, lg: 8 }}
          display={{ lg: "flex" }}
          alignItems={{ lg: "center" }}
          justifyContent={{ lg: "space-between" }}
        >
          <chakra.h2
            fontSize={{ base: "3xl", sm: "4xl" }}
            mr="10"
            fontWeight="normal"
            letterSpacing="tight"
            lineHeight="shorter"
            color="white"
            _dark={{ color: "gray.100" }}
          >
            <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{
            ease: "linear",
            x: { duration: 0.8 },
            opacity: { duration: 1 },
          }}
       
        >
            <chakra.span fontWeight={"bold"} mb="4" display="block">Find out more about our products</chakra.span>
            <chakra.span
              display="block"
              color="white"
              fontSize={{ base: "sm", sm: "md" }}
              _dark={{ color: "gray.500" }}
            >
            In today's world, physical and mental stress is taking a toll on health. Our unique products allow you to maintain good health and help in prevention and correction of chronic diseases.
            </chakra.span>
            </motion.div>
          </chakra.h2>
          <Stack
            direction={{ base: "column", sm: "row" }}
            mt={{ base: 8, lg: 0 }}
            flexShrink={{ lg: 0 }}
            ml="10"
          >
            
    
            <Button onClick={()=>scrollToSection("form")}  colorScheme='gray' px="8" rounded={"full"} variant='outline'>
            Get In Touch
  </Button>
          </Stack>
        </Box>
      </Box>
    </Flex>
      
  );
};
