import React from "react";
import { chakra, SimpleGrid, Flex, Icon, Text, Divider } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function Features(){
  const Feature = (props:any) => {
    return (
      <motion.div
      initial={{ opacity: 0, x: 100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{
        ease: "linear",
        x: { duration: 0.8 },
        opacity: { duration: 1 },
      }}
   
    >
      <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"}>
        <Icon
          boxSize={12}
          _light={{ color: "brand.700" }}
          mb={4}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          {props.icon}
        </Icon>
        <chakra.h3
          mb={3}
          fontSize="lg"
          lineHeight="shorter"
          fontWeight="bold"
          _light={{ color: "gray.900" }}
        >
          {props.title}
        </chakra.h3>
        <chakra.p
          lineHeight="tall"
          color="gray.600"
          _dark={{ color: "gray.400" }}
          textAlign={"center"}
        >
          {props.children}
        </chakra.p>
      </Flex>
      </motion.div>
    );
  };

  return (
    <section id="about">
    <Flex
      bg="#edf3f8"
      _dark={{ bg: "#3e3e3e" }}
      p={[2,2,20,20]}
      w="auto"
      justifyContent="center"
      flexDir={"column"}
      alignItems="center"
    >
         <Text fontSize={"4xl"}>About Us</Text>
      <Divider my="4" width={"100px"} color={"black"}/>
      <Text textAlign={"center"} fontSize={["xl","xl","2xl","2xl"]} mb="4">Patient's health is our topmost priority. Accuffy LifeSciences an innovative organization with unique pharmaceutical and nutraceutical products. Our products are well researched and result oriented.</Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing={20}
        px={{ base: 4, lg: 16, xl: 24 }}
        py={20}
        mx="auto"
        bg="white"
        _dark={{ bg: "gray.800" }}
        shadow="xl"
      >
        <Feature
          title="Pristine Quality"
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          }
        >
          Rigorous systems to ensure superior end products with quality raw material procurement and adherence to best manufacturing practices
        </Feature>

        <Feature
          title="Innovative products"
          icon={
            <path
              fillRule="evenodd"
              d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
              clipRule="evenodd"
            />
          }
        >
        Unique composition/delivery systems ensuring better bio-availability and effectiveness
        </Feature>

        <Feature
          title="Experienced Team"
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
            />
          }
        >
         Team with vast experience in the nutraceutical and pharmaceutical industry
        </Feature>
      </SimpleGrid>
    </Flex>
    </section>
  );
};