import {
  Box,

  HStack,

  Link,

  Text,
  VStack,

  Icon,
} from "@chakra-ui/react";
import { GrInstagram } from "react-icons/gr";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";

export default function Footer() {
  return (
    <Box  bg="#edf3f8"
    _dark={{ bg: "#3e3e3e" }}style={{ backgroundImage: "url(http://webdesign-finder.com/pharmaton-v2/wp-content/uploads/2022/04/map-texture.png)" }}>


      <VStack py={3}>
        <HStack justify="center">
          <Link href="https://www.facebook.com/profile.php?id=61554925950898&mibextid=9R9pXO" target="_blank" rel="noopener noreferrer">
            <Icon
              color="gray.800"
              _dark={{ color: "white" }}
              h="20px"
              w="20px"
              as={FaFacebookF}
            />
          </Link>
          <Link href="https://twitter.com/accuffy" target="_blank" rel="noopener noreferrer">
            <Icon
              color="gray.800"
              _dark={{ color: "white" }}
              h="20px"
              w="20px"
              as={FiTwitter}
            />
          </Link>
          <Link href="https://www.instagram.com/accuffylifesciences/" target="_blank" rel="noopener noreferrer">
            <Icon
              _dark={{ color: "white" }}
              h="20px"
              w="20px"
              as={GrInstagram}
            />
          </Link>
          <Link href="https://www.linkedin.com/in/accuffy-lifesciences-6a56602a6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
            <Icon
              _dark={{ color: "white" }}
              h="20px"
              w="20px"
              as={FaLinkedinIn}
            />
          </Link>
          
        </HStack>
        <Text textAlign="center" fontSize="smaller" _dark={{ color: "white" }}>
          &copy;Copyright. All rights reserved.
        </Text>
      </VStack>
    </Box>
  );
};

